import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PageSearch from '../../components/PageSearch';
import { tagFromURI } from '../../utils/tagToURI';
import { fetchFullTagRequest } from '../../actions/fullTags';

class PageSearchContainer extends Component {
  UNSAFE_componentWillMount() {
    this.checkTagExisting(this.props);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.checkTagExisting(nextProps);
    }
  }

  async checkTagExisting(props) {
    if (!props.isLoaded) {
      props.fetchFullTagRequest(tagFromURI(props.match.params.id));
    }
  }

  render() {
    if (this.props.fullTag) {
      return <Redirect to={`/gifs/${this.props.match.params.id}`} />;
    }

    return <PageSearch {...this.props} />;
  }
}

PageSearchContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

const mapStateToProps = (state, props) => {
  const mediaFilter = `search/${props.match.params.id}`;
  const mediaList = state.media.lists[mediaFilter];
  const firstMediaId = get(mediaList, 'ids.0');
  const firstMedia = state.media.entities[firstMediaId];
  const fullTag = state.fullTags[tagFromURI(props.match.params.id)];

  return {
    mediaFilter,
    firstMedia,
    fullTag,
    server: firstMedia && firstMedia.file && state.uploadServers.data[firstMedia.file.server],
    showHreflang: false,
    showLdJSON: false,
    locale: state.intl.locale,
    isMobile: state.env.isMobile,
    isUnsafeTag: false,
    count: get(state, ['media', 'lists', mediaFilter, 'count'], 0),
    name: tagFromURI(props.match.params.id),
    url: `/s/${props.match.params.id}`,
    isLoaded: get(mediaList, 'isLoaded'),
  };
};

const mapDispatchToProps = {
  fetchFullTagRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageSearchContainer);
